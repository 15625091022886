import React, { useState, useEffect, useCallback } from 'react';
import { db, auth } from './firebase';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { updateProfile, updateEmail } from 'firebase/auth';
import EditablePromptCard from './EditablePromptCard';
import PromptCard from './PromptCard';
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

function ProfileView({ user, onVote, userVotes, voteTotals, onRefreshVoteTotals, onFavorite, favoritedPrompts }) {
  const [userPrompts, setUserPrompts] = useState([]);
  const [profileData, setProfileData] = useState({ username: '', email: '' });
  const [editData, setEditData] = useState({ username: '', email: '' });
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('yourPrompts');
  const [isEditing, setIsEditing] = useState(false);

  const fetchUserData = useCallback(async () => {
   // console.log("Fetching user data"); // Debug log
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const newProfileData = {
        username: userData.username,
        email: user.email
      };
      setProfileData(newProfileData);
      if (!isEditing) {
        setEditData(newProfileData);
      }
    }

    const q = query(collection(db, 'prompts'), where('createdBy', '==', user.uid));
    const querySnapshot = await getDocs(q);
    const prompts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUserPrompts(prompts);

    onRefreshVoteTotals();
  }, [user, isEditing, onRefreshVoteTotals]);

  useEffect(() => {
   // console.log("ProfileView useEffect running"); // Debug log
    fetchUserData();
  }, [fetchUserData]);

  const handleEdit = async (promptId, newText) => {
    await updateDoc(doc(db, 'prompts', promptId), { text: newText });
    setUserPrompts(prevPrompts =>
      prevPrompts.map(p => p.id === promptId ? { ...p, text: newText } : p)
    );
  };

  const handleDelete = async (promptId) => {
    await deleteDoc(doc(db, 'prompts', promptId));
    setUserPrompts(prevPrompts => prevPrompts.filter(p => p.id !== promptId));
  };

  const handleProfileUpdate = async () => {
    setError('');
    try {
      // Check if username is unique
      const usernameQuery = query(collection(db, 'users'), where('username', '==', editData.username));
      const usernameSnapshot = await getDocs(usernameQuery);
      if (!usernameSnapshot.empty && usernameSnapshot.docs[0].id !== user.uid) {
        setError('Username already exists. Please choose a different one.');
        return;
      }
      // Update username in Firestore
      await updateDoc(doc(db, 'users', user.uid), { username: editData.username });
      // Update display name in Firebase Auth
      await updateProfile(auth.currentUser, { displayName: editData.username });
      // Update email if it has changed
      if (editData.email !== user.email) {
        await updateEmail(auth.currentUser, editData.email);
      }
      setProfileData(editData);
      setIsEditing(false);
    } catch (error) {
      setError('Failed to update profile: ' + error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
   // console.log(`Input changed: ${name} = ${value}`); // Debug log
    setEditData(prev => ({ ...prev, [name]: value }));
  };

  const handleStartEditing = () => {
    setEditData(profileData);
    setIsEditing(true);
  };

  const handleCancelEditing = () => {
    setEditData(profileData);
    setIsEditing(false);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">{profileData.username}'s Profile</h2>
      {error && <Alert variant="destructive" className="mb-4"><AlertDescription>{error}</AlertDescription></Alert>}
      {isEditing ? (
        <div className="mb-4 space-y-4">
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
            <Input 
              id="username" 
              name="username"
              type="text" 
              value={editData.username} 
              onChange={handleInputChange} 
              className="mt-1" 
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <Input 
              id="email" 
              name="email"
              type="email" 
              value={editData.email} 
              onChange={handleInputChange} 
              className="mt-1" 
            />
          </div>
          <Button onClick={handleProfileUpdate}>Save Changes</Button>
          <Button variant="outline" onClick={handleCancelEditing}>Cancel</Button>
        </div>
      ) : (
        <div className="mb-4">
          <p className="mb-2">Email: {profileData.email}</p>
          <Button onClick={handleStartEditing}>Edit Profile</Button>
        </div>
      )}
      
      <p className="mb-2">Total Upvotes: {voteTotals?.totalUpvotes || 0}</p>
      <p className="mb-4">Total Downvotes: {voteTotals?.totalDownvotes || 0}</p>
      
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="yourPrompts">Your Prompts</TabsTrigger>
          <TabsTrigger value="likedPrompts">Liked Prompts</TabsTrigger>
        </TabsList>
        <TabsContent value="yourPrompts">
          {userPrompts.map(prompt => (
            <EditablePromptCard 
              key={prompt.id} 
              prompt={prompt} 
              onVote={onVote}
              onEdit={handleEdit}
              onDelete={handleDelete}
              userVotes={userVotes}
              currentUser={user}
              isOwnPrompt={true}
            />
          ))}
        </TabsContent>
        <TabsContent value="likedPrompts">
          {favoritedPrompts.map(prompt => (
            <PromptCard
              key={prompt.id}
              prompt={prompt}
              onVote={onVote}
              userVotes={userVotes}
              currentUser={user}
              onUserClick={() => {}}
              isVoting={false}
              onFavorite={onFavorite}
              isFavorite={true}
            />
          ))}
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default React.memo(ProfileView);