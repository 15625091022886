import React, { useState, useEffect, useCallback, useRef } from 'react';
import { auth, db } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, query, getDocs, doc, getDoc, increment, setDoc, runTransaction, deleteField, updateDoc } from 'firebase/firestore';
import AuthPage from './AuthPage';
import PromptLeaderboard from './PromptLeaderboard';
import PromptForm from './PromptForm';
import ProfileView from './ProfileView';
import UserProfileCard from './UserProfileCard';
import { Button } from "@/components/ui/button";
import { LoginPromptModal } from './components/ui/login-prompt-modal';
import { Menu, X } from 'lucide-react';
import './App.css';
import { format } from 'date-fns'; // Add this import at the top of your file
import { BrowserRouter as Router } from 'react-router-dom';
import { usePageTracking } from './usePageTracking';
import CookieConsent from './components/CookieConsent';

// for google analytics tracking
function PageTracker() {
  usePageTracking();
  return null;
}

function App() {
  const [user, setUser] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [userVotes, setUserVotes] = useState({});
  const [view, setView] = useState('leaderboard');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAuthPage, setShowAuthPage] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [loginPromptMessage, setLoginPromptMessage] = useState('');
  const [pendingAction, setPendingAction] = useState(null);
  const [isVoting, setIsVoting] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userVoteTotals, setUserVoteTotals] = useState({});
  const userVoteTotalsRef = useRef({});
  const [favoritedPrompts, setFavoritedPrompts] = useState([]);

  const fetchUserVotes = useCallback(async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        setUserVotes(userDoc.data().votes || {});
      } else {
        // If the user document doesn't exist, create it
        const newUserData = {
          username: user?.displayName || '',
          email: user?.email || '',
          totalUpvotes: 0,
          totalDownvotes: 0,
          votes: {}
        };
        await setDoc(doc(db, "users", userId), newUserData);
        setUserVotes({});
      }
    } catch (error) {
     // console.error("Error fetching user votes:", error);
      setUserVotes({});
    }
  }, [user]);

  const fetchUserVoteTotals = useCallback(async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const data = userDoc.data();
        const totals = {
          totalUpvotes: data.totalUpvotes || 0,
          totalDownvotes: data.totalDownvotes || 0,
        };
        setUserVoteTotals(prevTotals => ({
          ...prevTotals,
          [userId]: totals
        }));
        userVoteTotalsRef.current[userId] = totals;
      }
    } catch (error) {
     // console.error("Error fetching user vote totals:", error);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        fetchUserVotes(user.uid);
        fetchUserVoteTotals(user.uid);
      }
      setLoading(false);
    });

    fetchPrompts();

    return () => unsubscribe();
  }, [fetchUserVotes, fetchUserVoteTotals]);

  const fetchPrompts = async () => {
    const promptsQuery = query(collection(db, 'prompts'));
    const promptsSnapshot = await getDocs(promptsQuery);
    const promptsList = promptsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setPrompts(promptsList);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setUserVotes({});
      setView('leaderboard');
    } catch (error) {
     // console.error("Error signing out:", error);
    }
  };

  const handleUserClick = (userId) => {
    if (userId === 'profile') {
      setView('profile');
    } else {
      setSelectedUserId(userId);
      setView('userProfile');
    }
  };

  const handleVote = async (promptId, voteType) => {
   // console.log("handleVote called", { promptId, voteType, user });
    if (!user) {
     // console.log("User not logged in, showing login prompt");
      setLoginPromptMessage('You must be logged in to vote on prompts.');
      setShowLoginPrompt(true);
      setPendingAction(() => () => {
       // console.log("Executing pending vote action");
        handleVoteLoggedIn(promptId, voteType);
      });
    } else {
     // console.log("User logged in, proceeding with vote");
      handleVoteLoggedIn(promptId, voteType);
    }
  };

  const handleVoteLoggedIn = async (promptId, voteType) => {
    if (isVoting) return;
    setIsVoting(true);
    
    try {
      const promptRef = doc(db, 'prompts', promptId);
      const userRef = doc(db, 'users', user.uid);

      const result = await runTransaction(db, async (transaction) => {
        const promptDoc = await transaction.get(promptRef);
        if (!promptDoc.exists()) {
          throw new Error("Prompt does not exist!");
        }

        const promptData = promptDoc.data();
        const promptCreatorId = promptData.createdBy;
        const promptCreatorRef = doc(db, 'users', promptCreatorId);
        const promptCreatorDoc = await transaction.get(promptCreatorRef);

        if (!promptCreatorDoc.exists()) {
          throw new Error("Prompt creator does not exist!");
        }

        const userDoc = await transaction.get(userRef);
        if (!userDoc.exists()) {
          throw new Error("User does not exist!");
        }

        const userData = userDoc.data();
        const userVotesData = userData.votes || {};
        const currentVote = userVotesData[promptId] || 'none';

       // console.log('Current vote status:', { promptId, currentVote, userVotesData });

        let voteChange = 0;
        let creatorUpvoteChange = 0;
        let creatorDownvoteChange = 0;
        let newVoteStatus = currentVote;

        if (voteType === 'up') {
          if (currentVote === 'up') {
            voteChange = -1;
            creatorUpvoteChange = -1;
            newVoteStatus = 'none';
          } else if (currentVote === 'down') {
            voteChange = 2;
            creatorUpvoteChange = 1;
            creatorDownvoteChange = -1;
            newVoteStatus = 'up';
          } else {
            voteChange = 1;
            creatorUpvoteChange = 1;
            newVoteStatus = 'up';
          }
        } else if (voteType === 'down') {
          if (currentVote === 'down') {
            voteChange = 1;
            creatorDownvoteChange = -1;
            newVoteStatus = 'none';
          } else if (currentVote === 'up') {
            voteChange = -2;
            creatorUpvoteChange = -1;
            creatorDownvoteChange = 1;
            newVoteStatus = 'down';
          } else {
            voteChange = -1;
            creatorDownvoteChange = 1;
            newVoteStatus = 'down';
          }
        }

        // Update the prompt's vote count
        transaction.update(promptRef, {
          votes: increment(voteChange),
        });

        // Update the prompt creator's total upvotes/downvotes
        const updatedCreatorData = {
          totalUpvotes: (promptCreatorDoc.data().totalUpvotes || 0) + creatorUpvoteChange,
          totalDownvotes: (promptCreatorDoc.data().totalDownvotes || 0) + creatorDownvoteChange,
        };
        transaction.update(promptCreatorRef, updatedCreatorData);

        // Update the voting user's vote record
        if (newVoteStatus === 'none') {
          transaction.update(userRef, {
            [`votes.${promptId}`]: deleteField()
          });
        } else {
          transaction.update(userRef, {
            [`votes.${promptId}`]: newVoteStatus
          });
        }

        // Return the changes so we can use them outside the transaction
        return { voteChange, newVoteStatus, promptCreatorId, updatedCreatorData };
      });

     // console.log('Transaction result:', result);

     // console.log('Before state updates:', { prompts, userVotes, userVoteTotals });

      setUserVoteTotals(prevTotals => {
        const newTotals = {
          ...prevTotals,
          [result.promptCreatorId]: {
            totalUpvotes: result.updatedCreatorData.totalUpvotes,
            totalDownvotes: result.updatedCreatorData.totalDownvotes,
          }
        };
       // console.log('New userVoteTotals:', newTotals);
        return newTotals;
      });

      setPrompts((prevPrompts) => {
        const newPrompts = prevPrompts.map((prompt) =>
          prompt.id === promptId
            ? { ...prompt, votes: prompt.votes + result.voteChange }
            : prompt
        );
       // console.log('New prompts:', newPrompts);
        return newPrompts;
      });

      setUserVotes((prev) => {
        const newUserVotes = {
          ...prev,
          [promptId]: result.newVoteStatus,
        };
       // console.log('New userVotes:', newUserVotes);
        return newUserVotes;
      });

     // console.log('After state updates');

      // Fetch the latest vote totals for the prompt creator
      await fetchUserVoteTotals(result.promptCreatorId);

    } catch (e) {
     // console.error("Transaction failed: ", e);
      // Optionally, show an error message to the user
    } finally {
      setIsVoting(false);
    }
  };

  const handleLoginFromPrompt = useCallback(() => {
   // console.log("handleLoginFromPrompt called");
    setShowLoginPrompt(false);
    setShowAuthPage(true);
  }, []);

  const handleCreatePromptClick = () => {
    if (!user) {
      setLoginPromptMessage('You must be logged in to submit a prompt.');
      setShowLoginPrompt(true);
      setPendingAction(() => () => setView('create'));
    } else {
      setView('create');
    }
  };

  const handleLogin = () => {
    setShowAuthPage(true);
  };

  const handleAuthComplete = async (user) => {
   // console.log("handleAuthComplete called", { user });
    setUser(user);
    setShowAuthPage(false);
    if (pendingAction) {
     // console.log("Executing pending action");
      pendingAction();
      setPendingAction(null);
    } else {
     // console.log("No pending action to execute");
    }
    // Fetch user votes after successful authentication
    if (user) {
      await fetchUserVotes(user.uid);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleFavorite = async (promptId) => {
    if (!user) {
      setLoginPromptMessage('You must be logged in to favorite prompts.');
      setShowLoginPrompt(true);
      setPendingAction(() => () => handleFavoriteLoggedIn(promptId));
    } else {
      handleFavoriteLoggedIn(promptId);
    }
  };

  const handleFavoriteLoggedIn = async (promptId) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();
      const favorites = userData.favorites || [];

      if (favorites.includes(promptId)) {
        // Remove from favorites
        await updateDoc(userRef, {
          favorites: favorites.filter(id => id !== promptId)
        });
        setFavoritedPrompts(prevFavorites => prevFavorites.filter(prompt => prompt.id !== promptId));
      } else {
        // Add to favorites
        await updateDoc(userRef, {
          favorites: [...favorites, promptId]
        });
        const promptDoc = await getDoc(doc(db, 'prompts', promptId));
        if (promptDoc.exists()) {
          setFavoritedPrompts(prevFavorites => [...prevFavorites, { id: promptId, ...promptDoc.data() }]);
        }
      }
    } catch (error) {
      console.error("Error updating favorites:", error);
    }
  };

  useEffect(() => {
    // ... existing code

    const fetchFavoritedPrompts = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const favorites = userDoc.data().favorites || [];
          const favPrompts = await Promise.all(
            favorites.map(async (promptId) => {
              const promptDoc = await getDoc(doc(db, 'prompts', promptId));
              return promptDoc.exists() ? { id: promptDoc.id, ...promptDoc.data() } : null;
            })
          );
          setFavoritedPrompts(favPrompts.filter(Boolean));
        }
      }
    };

    fetchFavoritedPrompts();
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <PageTracker />
      <CookieConsent />
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <header className="bg-white shadow-sm">
        <div className="container mx-auto px-4 py-4 flex items-center justify-between">
          <div 
            className="flex items-center space-x-4 cursor-pointer" 
            onClick={() => setView('leaderboard')}
          >
            <img 
              src="/Logo-GPT-Prompt-Leaderboard-small.png" 
              alt="GPT Prompts Leaderboard Logo" 
              width={40} 
              height={40} 
            />
            <p className="text-3xl font-bold text-gray-900">
              GPT Prompts Leaderboard
            </p>
          </div>
          <nav className="hidden md:flex space-x-2">
            <Button onClick={() => setView('leaderboard')} variant={view === 'leaderboard' ? 'default' : 'outline'}>Leaderboard</Button>
            <Button onClick={handleCreatePromptClick} variant={view === 'create' ? 'default' : 'outline'}>Create Prompt</Button>
            {user && (
              <Button onClick={() => setView('profile')} variant={view === 'profile' ? 'default' : 'outline'}>Profile</Button>
            )}
            {user ? (
              <Button onClick={handleSignOut} variant="destructive">Sign Out</Button>
            ) : (
              <Button onClick={handleLogin} variant="default">Sign In</Button>
            )}
          </nav>
          <button className="md:hidden" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        {isMobileMenuOpen && (
          <div className="md:hidden">
            <nav className="flex flex-col space-y-2 p-4">
              <Button onClick={() => { setView('leaderboard'); setIsMobileMenuOpen(false); }} variant={view === 'leaderboard' ? 'default' : 'outline'}>Leaderboard</Button>
              <Button onClick={() => { handleCreatePromptClick(); setIsMobileMenuOpen(false); }} variant={view === 'create' ? 'default' : 'outline'}>Create Prompt</Button>
              {user && (
                <Button onClick={() => { setView('profile'); setIsMobileMenuOpen(false); }} variant={view === 'profile' ? 'default' : 'outline'}>Profile</Button>
              )}
              {user ? (
                <Button onClick={() => { handleSignOut(); setIsMobileMenuOpen(false); }} variant="destructive">Sign Out</Button>
              ) : (
                <Button onClick={() => { handleLogin(); setIsMobileMenuOpen(false); }} variant="default">Sign In</Button>
              )}
            </nav>
          </div>
        )}
      </header>
      <main className="container mx-auto px-4 py-8 flex-grow">
        {view === 'leaderboard' && (
          <PromptLeaderboard
            prompts={prompts}
            onVote={handleVote}
            userVotes={userVotes}
            onUserClick={handleUserClick}
            currentUser={user}
            onLogin={handleLoginFromPrompt}
            isVoting={isVoting}
            onFavorite={handleFavorite}  // Make sure this is passed
            favoritedPrompts={favoritedPrompts}  // Make sure this is passed
          />
        )}
        {view === 'create' && user && <PromptForm user={user} />}
        {view === 'profile' && user && (
          <ProfileView 
            user={user} 
            onVote={handleVote} 
            userVotes={userVotes} 
            voteTotals={userVoteTotals[user.uid]}
            onRefreshVoteTotals={() => fetchUserVoteTotals(user.uid)}
            onFavorite={handleFavorite}
            favoritedPrompts={favoritedPrompts}
          />
        )}
        {view === 'userProfile' && (
          <UserProfileCard
            userId={selectedUserId}
            onClose={() => setView('leaderboard')}
            onVote={handleVote}
            userVotes={userVotes}
            currentUser={user}
            isVoting={isVoting}
            voteTotals={userVoteTotals[selectedUserId]}
            onRefreshVoteTotals={() => fetchUserVoteTotals(selectedUserId)}
            onFavorite={handleFavorite}  // Add this line
            favoritedPrompts={favoritedPrompts}  // Add this line
          />
        )}
      </main>
      <footer className="bg-gray-100 py-4 mt-8">
        <div className="container mx-auto px-4 text-center text-sm text-gray-600">
          © {format(new Date(), 'yyyy')} GPT Prompts Leaderboard. - Made by{' '}
          <a 
            href="https://www.linkedin.com/in/julianbentsingh/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            Julian Bent Singh
          </a>{' '}
          - Powered by{' '}
          <a 
            href="https://somecaptions.dk/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            SoMe Captions
          </a>{' '}
          - All rights reserved.
        </div>
      </footer>
      {showAuthPage && (
        <AuthPage 
          onAuthComplete={handleAuthComplete} 
          onClose={() => {
           // console.log("AuthPage closed");
            setShowAuthPage(false);
          }}
        />
      )}
      {showLoginPrompt && (
        <LoginPromptModal
          key="main-login-prompt"
          isOpen={true}
          onClose={() => {
           // console.log("LoginPromptModal closed");
            setShowLoginPrompt(false);
          }}
          onLogin={handleLoginFromPrompt}
          message={loginPromptMessage}
        />
      )}
    </div>
  </Router>
  );
}


export default App;