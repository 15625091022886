import React from 'react';
import { X } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";

export function LoginPromptModal({ isOpen, onClose, onLogin, message }) {
 // console.log("LoginPromptModal rendered", { isOpen, message, onLogin: typeof onLogin });

  if (!isOpen) return null;

  const handleLogin = () => {
   // console.log("Login/Create Account button clicked");
    if (typeof onLogin === 'function') {
      onLogin();
    } else {
     // console.error("onLogin is not a function or is undefined", onLogin);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <Card className="w-96 max-w-md relative">
        <Button 
          variant="ghost" 
          size="icon" 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <X className="h-4 w-4" />
        </Button>
        <CardHeader>
          <CardTitle className="text-center">Login Required</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-center mb-4">{message}</p>
        </CardContent>
        <CardFooter className="flex justify-center">
          <Button onClick={handleLogin}>Login / Create Account</Button>
        </CardFooter>
      </Card>
    </div>
  );
}