import { useEffect } from 'react';

const CookieConsent = () => {
  useEffect(() => {
    if (window.Cookiebot) {
      window.Cookiebot.runScripts();
    }
  }, []);

  return null;
};

export default CookieConsent;