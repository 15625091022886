import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, query, orderBy, onSnapshot, limit } from 'firebase/firestore';
import { ClockIcon, TrendingUpIcon } from 'lucide-react';
import PromptCard from './PromptCard';
import { LoginPromptModal } from './components/ui/login-prompt-modal';
import { ScrollArea } from "@/components/ui/scroll-area";

const categories = [
  "All", "Ads", "Business", "Chatbot", "Coach", "Code", "Conversion", "Copywriting",
  "Education", "Email", "Facebook", "Fashion", "Finance", "Fix", "Flux",
  "Food", "Fun", "Funny", "Games", "Health", "Ideogram", "Ideas", "Instagram",
  "Language", "LinkedIn", "Marketing", "Midjourney", "Music", "Plan", "Prompts",
  "Sales", "SEO", "Social", "Sport", "Stable Diffusion", "Study", "Summarise",
  "TikTok", "Translate", "Travel", "Twitter/X", "Writing"
];

const gptModels = ["All", "GPT-4", "GPT-4o Mini", "GPT-4o", "o1-mini", "o1-preview", "Claude 3 Opus", "Claude 3.5 Sonnet", "Gemini 1.5 Flash", "Gemini 1.5 Pro"];

const languages = [
  { name: "All", code: "ALL" },
  { name: "Danish", code: "DA" },
  { name: "English", code: "EN" },
  { name: "Spanish", code: "ES" },
  { name: "French", code: "FR" },
  { name: "German", code: "DE" },
  { name: "Chinese", code: "ZH" },
  { name: "Japanese", code: "JA" },
  // Add more languages as needed
];
function PromptLeaderboard({ onVote, userVotes, onUserClick, currentUser, onLogin, isVoting, onFavorite, favoritedPrompts }) {
  const [prompts, setPrompts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedGptModel, setSelectedGptModel] = useState('All');
  const [selectedLanguage, setSelectedLanguage] = useState('ALL');
  const [activeTab, setActiveTab] = useState('newest');
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  useEffect(() => {
    const q = query(collection(db, 'prompts'), orderBy('createdAt', 'desc'), limit(100));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const promptsArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPrompts(promptsArray);
    });
    return () => unsubscribe();
  }, []);

  const filteredPrompts = prompts
    .filter(prompt => 
      (selectedCategory === 'All' || prompt.category === selectedCategory) &&
      (selectedGptModel === 'All' || prompt.gptModel === selectedGptModel) &&
      (selectedLanguage === 'ALL' || prompt.language === selectedLanguage)
    );

  const displayPrompts = activeTab === 'newest' 
    ? filteredPrompts
    : [...filteredPrompts].sort((a, b) => b.votes - a.votes);

  const handleVote = (promptId, voteType, loginRequired) => {
    if (loginRequired) {
      setShowLoginPrompt(true);
    } else {
      onVote(promptId, voteType);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-6">GPT Prompts Leaderboard</h1>
      <p className="text-center mb-8">Find, vote and share GPT prompts right here!👇</p>
      <p className="text-center mb-12">The process is simple: 1️⃣. Find a prompt. 2️⃣. Copy the prompt. 3️⃣. Try the prompt out in a LLM. 4️⃣. Up or down vote the prompt. </p>
      
      <div className="flex flex-col md:flex-row">
        {/* Desktop layout */}
        <div className="hidden md:block md:w-1/4 md:pr-4">
          <h2 className="text-xl font-semibold mb-4">GPT Model</h2>
          <ScrollArea className="h-40 mb-8">
            <div className="pr-4">
              {gptModels.map((model) => (
                <button
                  key={model}
                  className={`w-full text-left px-4 py-2 rounded ${selectedGptModel === model ? 'bg-black text-white' : 'hover:bg-gray-100'}`}
                  onClick={() => setSelectedGptModel(model)}
                >
                  {model}
                </button>
              ))}
            </div>
          </ScrollArea>
          
          <h2 className="text-xl font-semibold mb-4">Category</h2>
          <ScrollArea className="h-40 mb-8">
            <div className="pr-4">
              {categories.map((category) => (
                <button
                  key={category}
                  className={`w-full text-left px-4 py-2 rounded ${selectedCategory === category ? 'bg-black text-white' : 'hover:bg-gray-100'}`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </ScrollArea>

          <h2 className="text-xl font-semibold mb-4">Language</h2>
          <ScrollArea className="h-40">
            <div className="pr-4">
              {languages.map((lang) => (
                <button
                  key={lang.code}
                  className={`w-full text-left px-4 py-2 rounded ${selectedLanguage === lang.code ? 'bg-black text-white' : 'hover:bg-gray-100'}`}
                  onClick={() => setSelectedLanguage(lang.code)}
                >
                  {lang.name}
                </button>
              ))}
            </div>
          </ScrollArea>
        </div>
        
        {/* Mobile layout */}
        <div className="md:hidden mb-4">
          <div className="flex space-x-4">
            <div className="w-1/3">
              <h2 className="text-sm font-semibold mb-1">GPT Model</h2>
              <ScrollArea className="h-24 rounded-md border">
                <div className="p-2">
                  {gptModels.map((model) => (
                    <button
                      key={model}
                      className={`w-full text-left px-2 py-1 text-sm ${selectedGptModel === model ? 'bg-black text-white' : 'hover:bg-gray-100'}`}
                      onClick={() => setSelectedGptModel(model)}
                    >
                      {model}
                    </button>
                  ))}
                </div>
              </ScrollArea>
            </div>
            
            <div className="w-1/3">
              <h2 className="text-sm font-semibold mb-1">Category</h2>
              <ScrollArea className="h-24 rounded-md border">
                <div className="p-2">
                  {categories.map((category) => (
                    <button
                      key={category}
                      className={`w-full text-left px-2 py-1 text-sm ${selectedCategory === category ? 'bg-black text-white' : 'hover:bg-gray-100'}`}
                      onClick={() => setSelectedCategory(category)}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </ScrollArea>
            </div>

            <div className="w-1/3">
              <h2 className="text-sm font-semibold mb-1">Language</h2>
              <ScrollArea className="h-24 rounded-md border">
                <div className="p-2">
                  {languages.map((lang) => (
                    <button
                      key={lang.code}
                      className={`w-full text-left px-2 py-1 text-sm ${selectedLanguage === lang.code ? 'bg-black text-white' : 'hover:bg-gray-100'}`}
                      onClick={() => setSelectedLanguage(lang.code)}
                    >
                      {lang.name}
                    </button>
                  ))}
                </div>
              </ScrollArea>
            </div>
          </div>
        </div>
        
        <div className="md:w-3/4 md:pl-4">
          <div className="mb-6 flex items-center">
            <button
              className={`mr-2 px-3 py-1 rounded-md flex items-center text-sm ${activeTab === 'newest' ? 'bg-gray-200' : 'bg-white border'}`}
              onClick={() => setActiveTab('newest')}
            >
              <ClockIcon className="mr-1" size={14} /> Newest
            </button>
            <button
              className={`px-3 py-1 rounded-md flex items-center text-sm ${activeTab === 'top' ? 'bg-gray-200' : 'bg-white border'}`}
              onClick={() => setActiveTab('top')}
            >
              <TrendingUpIcon className="mr-1" size={14} /> Top
            </button>
          </div>
          
          <div className="space-y-4">
            {displayPrompts.map(prompt => (
              <PromptCard 
                key={prompt.id} 
                prompt={prompt} 
                onVote={handleVote} 
                userVotes={userVotes}
                onUserClick={onUserClick}
                currentUser={currentUser}
                isVoting={isVoting}
                onFavorite={onFavorite}
                isFavorite={favoritedPrompts.some(fp => fp.id === prompt.id)}
              />
            ))}
          </div>
        </div>
      </div>
      {showLoginPrompt && (
        <LoginPromptModal
          isOpen={true}
          onClose={() => setShowLoginPrompt(false)}
          onLogin={onLogin}
          message="You must be logged in to vote on prompts."
        />
      )}
    </div>
  );
}

export default PromptLeaderboard;