import React, { useState } from 'react';
import { db } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { CheckCircleIcon } from 'lucide-react'; // Import the CheckCircleIcon

const categories = [
  "Ads", "Business", "Chatbot", "Coach", "Code", "Conversion", "Copywriting",
  "Education", "Email", "Facebook", "Fashion", "Finance", "Fix", "Flux",
  "Food", "Fun", "Funny", "Games", "Health", "Ideogram", "Ideas", "Instagram",
  "Language", "LinkedIn", "Marketing", "Midjourney", "Music", "Plan", "Prompts",
  "Sales", "SEO", "Social", "Sport", "Stable Diffusion", "Study", "Summarise",
  "TikTok", "Translate", "Travel", "Twitter/X", "Writing"
];

const gptModels = [
  "GPT-4", "GPT-4o Mini", "GPT-4o", "o1-mini", "o1-preview",
  "Claude 3 Opus", "Claude 3.5 Sonnet", "Gemini 1.5 Flash", "Gemini 1.5 Pro"
];

const languages = [
  { name: "Danish", code: "DA" },
  { name: "English", code: "EN" },
  { name: "Spanish", code: "ES" },
  { name: "French", code: "FR" },
  { name: "German", code: "DE" },
  { name: "Chinese", code: "ZH" },
  { name: "Japanese", code: "JA" },
  // Add more languages as needed
];

function PromptForm({ user }) {
  const [promptText, setPromptText] = useState('');
  const [category, setCategory] = useState('');
  const [gptModel, setGptModel] = useState('');
  const [language, setLanguage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!promptText.trim() || !category || !gptModel || !language) {
      setError('Please fill in all fields: Prompt, Category, GPT Model, and Language are required.');
      return;
    }

    try {
      await addDoc(collection(db, 'prompts'), {
        text: promptText,
        category,
        gptModel,
        language,
        createdBy: user.uid,
        votes: 0,
        createdAt: new Date()
      });
      setPromptText('');
      setCategory('');
      setGptModel('');
      setLanguage('');
      setSuccess('Prompt submitted successfully!');
      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(''), 5000);
    } catch (error) {
      setError('Failed to submit prompt. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {success && (
        <Alert className="bg-green-50 border-green-200">
          <CheckCircleIcon className="h-4 w-4 text-green-600" />
          <AlertDescription className="text-green-700">{success}</AlertDescription>
        </Alert>
      )}
      {error && (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <Textarea
        value={promptText}
        onChange={(e) => setPromptText(e.target.value)}
        placeholder="Enter your prompt"
        className="w-full"
        rows={5}
        required
      />
      <Select value={category} onValueChange={setCategory}>
        <SelectTrigger>
          <SelectValue placeholder="Select category" />
        </SelectTrigger>
        <SelectContent>
          {categories.map((cat) => (
            <SelectItem key={cat} value={cat}>{cat}</SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Select value={gptModel} onValueChange={setGptModel}>
        <SelectTrigger>
          <SelectValue placeholder="Select GPT model" />
        </SelectTrigger>
        <SelectContent>
          {gptModels.map((model) => (
            <SelectItem key={model} value={model}>{model}</SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Select value={language} onValueChange={setLanguage}>
        <SelectTrigger>
          <SelectValue placeholder="Select language" />
        </SelectTrigger>
        <SelectContent>
          {languages.map((lang) => (
            <SelectItem key={lang.code} value={lang.code}>{lang.name}</SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Button type="submit">Submit Prompt</Button>
    </form>
  );
}

export default PromptForm;