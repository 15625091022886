import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { ArrowUpIcon, ArrowDownIcon, CopyIcon, PlusIcon, MinusIcon, HeartIcon } from 'lucide-react';
import * as Tooltip from '@radix-ui/react-tooltip';

const MAX_CHARS_DESKTOP = 500;
const MAX_CHARS_MOBILE = 265;

function PromptCard({ prompt, onVote, userVotes, currentUser, onUserClick, isVoting, onFavorite, isFavorite }) {
  const [username, setUsername] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [localVoteCount, setLocalVoteCount] = useState(prompt.votes || 0);
  const [localUserVote, setLocalUserVote] = useState(userVotes[prompt.id] || 'none');
  
  // Add this line
  const isOwnPrompt = currentUser && prompt.createdBy === currentUser.uid;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 640);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
   // console.log('PromptCard received new props:', { prompt, userVotes, isVoting });
  }, [prompt, userVotes, isVoting]);

  useEffect(() => {
    const fetchUsername = async () => {
      if (prompt.createdBy) {
        const userDoc = await getDoc(doc(db, 'users', prompt.createdBy));
        if (userDoc.exists()) {
          setUsername(userDoc.data().username || 'Anonymous');
        }
      }
    };
    fetchUsername();
  }, [prompt.createdBy]);

  useEffect(() => {
    setLocalVoteCount(prompt.votes || 0);
    setLocalUserVote(userVotes[prompt.id] || 'none');
  }, [prompt.votes, prompt.id, userVotes]);

  const handleCopy = () => {
    navigator.clipboard.writeText(prompt.text);
    setShowCopiedTooltip(true);
    setTimeout(() => setShowCopiedTooltip(false), 2000);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderPromptText = () => {
    const maxChars = isMobile ? MAX_CHARS_MOBILE : MAX_CHARS_DESKTOP;
    const formattedText = prompt.text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

    return (
      <div className="bg-gray-100 p-4 rounded-md mb-4 overflow-x-auto relative">
        <pre className="text-sm font-mono whitespace-pre-wrap">
          {prompt.text.length <= maxChars || isExpanded
            ? formattedText
            : (
              <>
                {prompt.text.slice(0, maxChars).split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
                ...
              </>
            )
          }
        </pre>
        <div className="mt-2 flex justify-between items-center">
          {prompt.text.length > maxChars && (
            <button
              onClick={toggleExpand}
              className="text-indigo-600 hover:text-indigo-800 font-medium flex items-center"
            >
              {isExpanded ? (
                <>
                  <MinusIcon className="h-4 w-4 mr-1" /> See less
                </>
              ) : (
                <>
                  <PlusIcon className="h-4 w-4 mr-1" /> See more
                </>
              )}
            </button>
          )}
        </div>
      </div>
    );
  };

  const handleVote = (voteType) => {
   // console.log('Vote clicked:', { promptId: prompt.id, voteType });
    onVote(prompt.id, voteType);
    
    // Optimistically update the local state
    if (voteType === 'up') {
      if (localUserVote === 'none') {
        setLocalVoteCount(prev => prev + 1);
        setLocalUserVote('up');
      } else if (localUserVote === 'up') {
        setLocalVoteCount(prev => prev - 1);
        setLocalUserVote('none');
      } else {
        setLocalVoteCount(prev => prev + 2);
        setLocalUserVote('up');
      }
    } else if (voteType === 'down') {
      if (localUserVote === 'none') {
        setLocalVoteCount(prev => prev - 1);
        setLocalUserVote('down');
      } else if (localUserVote === 'down') {
        setLocalVoteCount(prev => prev + 1);
        setLocalUserVote('none');
      } else {
        setLocalVoteCount(prev => prev - 2);
        setLocalUserVote('down');
      }
    }
  };

  const renderVoteButtons = () => {
    if (isOwnPrompt) {
      return (
        <div className="flex space-x-1">
          <Button
            size="sm"
            variant="outline"
            disabled
            className="text-gray-400 cursor-not-allowed">
            <ArrowUpIcon className="h-4 w-4" />
          </Button>
          <Button
            size="sm"
            variant="outline"
            disabled
            className="text-gray-400 cursor-not-allowed">
            <ArrowDownIcon className="h-4 w-4" />
          </Button>
        </div>
      );
    }

    return (
      <div className="flex space-x-1">
        <Button
          size="sm"
          variant={localUserVote === 'up' ? 'default' : 'outline'}
          onClick={() => handleVote('up')}
          disabled={isVoting}
          className="text-green-600 hover:text-green-700 hover:bg-green-100 transition-colors">
          <ArrowUpIcon className="h-4 w-4" />
        </Button>
        <Button
          size="sm"
          variant={localUserVote === 'down' ? 'default' : 'outline'}
          onClick={() => handleVote('down')}
          disabled={isVoting}
          className="text-red-600 hover:text-red-700 hover:bg-red-100 transition-colors">
          <ArrowDownIcon className="h-4 w-4" />
        </Button>
      </div>
    );
  };

  const handleUserClick = (e) => {
    e.preventDefault();
    if (currentUser && prompt.createdBy === currentUser.uid) {
      onUserClick('profile'); // Direct to ProfileView
    } else {
      onUserClick(prompt.createdBy); // Direct to UserProfileCard
    }
  };

  const handleFavorite = (e) => {
    e.stopPropagation();
    onFavorite(prompt.id);
  };

  return (
    <div className="group relative">
      <div className="absolute -inset-0.5 bg-gradient-to-r from-pink-300/30 via-purple-300/30 to-blue-300/30 rounded-lg blur-md opacity-0 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
      <Card className="w-full mb-4 transition-all duration-300 ease-in-out transform group-hover:scale-[1.02] bg-white relative">
        <CardContent className="p-4 sm:p-6">
          <div className="flex justify-end mb-2 space-x-2">
            <Tooltip.Provider>
              <Tooltip.Root open={showCopiedTooltip}>
                <Tooltip.Trigger asChild>
                  <button
                    onClick={handleCopy}
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    <CopyIcon className="h-4 w-4" />
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Content className="bg-black text-white p-2 rounded">
                  Copied!
                </Tooltip.Content>
              </Tooltip.Root>
            </Tooltip.Provider>
            {!isOwnPrompt && (
              <button
                onClick={handleFavorite}
                className={`p-1 rounded-full ${
                  isFavorite ? 'text-red-500' : 'text-gray-400'
                } hover:text-red-500 transition-colors`}
              >
                <HeartIcon className="h-4 w-4" />
              </button>
            )}
          </div>
          {renderPromptText()}
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-4">
            <div className="flex items-center space-x-2 mb-2 sm:mb-0">
              <p className="font-semibold text-2xl">{localVoteCount}</p>
              {renderVoteButtons(localUserVote)}
            </div>
            <div className="flex flex-wrap items-center space-x-2">
              <button
                onClick={handleUserClick}
                className="text-sm font-medium text-indigo-600 hover:text-indigo-800 transition-colors mb-2 sm:mb-0"
              >
                {username}
              </button>
              <Badge variant="secondary" className="bg-indigo-100 text-indigo-800 mb-2 sm:mb-0">{prompt.category}</Badge>
              <Badge variant="outline" className="bg-gray-100 text-gray-800 mb-2 sm:mb-0">{prompt.gptModel}</Badge>
              <Badge variant="outline" className="bg-blue-100 text-blue-800 mb-2 sm:mb-0">{prompt.language}</Badge>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default PromptCard;