import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCwb7P0HxPDJXIXS32zJS_qwVeLC8qaXKg",
    authDomain: "prompt-voting-app-native.firebaseapp.com",
    projectId: "prompt-voting-app-native",
    storageBucket: "prompt-voting-app-native.appspot.com",
    messagingSenderId: "749108675080",
    appId: "1:749108675080:web:6c39e6f4bd100fb33687bd"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);