import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { Button } from "@/components/ui/button";
import PromptCard from './PromptCard';

function UserProfileCard({ userId, onClose, onVote, userVotes, currentUser, isVoting, voteTotals, onRefreshVoteTotals, onFavorite, favoritedPrompts }) {
  const [userData, setUserData] = useState(null);
  const [userPrompts, setUserPrompts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        setError("User ID is missing");
        return;
      }

      try {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const data = userDocSnap.data();
          setUserData(data);
        } else {
          setError("User not found");
        }

        const promptsQuery = query(collection(db, 'prompts'), where('createdBy', '==', userId));
        const promptsSnapshot = await getDocs(promptsQuery);
        const prompts = promptsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUserPrompts(prompts);

        // Always refresh vote totals when the component mounts or updates
        if (onRefreshVoteTotals) {
          onRefreshVoteTotals();
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Error loading user profile. Please try again later.");
      }
    };

    fetchUserData();
  }, [userId, onRefreshVoteTotals]);

  const handleFavorite = (promptId) => {
    if (onFavorite) {
      onFavorite(promptId);
    }
  };

  // Function to update local prompt state after voting
  const updatePromptAfterVote = (promptId, voteChange) => {
    setUserPrompts(prevPrompts =>
      prevPrompts.map(prompt =>
        prompt.id === promptId
          ? { ...prompt, votes: (prompt.votes || 0) + voteChange }
          : prompt
      )
    );
  };

  // Wrapper for onVote to update local state
  const handleVote = async (promptId, voteType) => {
    const prevVote = userVotes[promptId];
    let voteChange = 0;

    if (voteType === 'up') {
      if (prevVote === 'up') voteChange = -1;
      else if (prevVote === 'down') voteChange = 2;
      else voteChange = 1;
    } else if (voteType === 'down') {
      if (prevVote === 'down') voteChange = 1;
      else if (prevVote === 'up') voteChange = -2;
      else voteChange = -1;
    }

    updatePromptAfterVote(promptId, voteChange);
    
    // Call the original onVote function
    await onVote(promptId, voteType);
  };

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600 mb-4">{error}</h2>
          <Button
            onClick={onClose}
            variant="outline"
            className="mt-4"
          >
            Back to Leaderboard
          </Button>
        </div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <p className="text-xl">Loading user profile...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold">{userData?.username || 'Anonymous'}'s Profile</h2>
        <Button
          onClick={onClose}
          variant="link"
          className="text-blue-500 hover:text-blue-700"
        >
          Back to Leaderboard
        </Button>
      </div>
      <div className="mb-8">
        <p className="text-xl mb-2">Total Upvotes: {voteTotals?.totalUpvotes || 0}</p>
        <p className="text-xl mb-4">Total Downvotes: {voteTotals?.totalDownvotes || 0}</p>
      </div>
      <h3 className="text-2xl font-semibold mb-4">Prompts:</h3>
      {userPrompts.sort((a, b) => (b.votes || 0) - (a.votes || 0)).map(prompt => (
        <PromptCard
          key={prompt.id}
          prompt={prompt}
          onVote={handleVote}
          userVotes={userVotes || {}}
          currentUser={currentUser}
          isVoting={isVoting}
          onUserClick={() => {}} // We don't need to handle user clicks here
          onFavorite={handleFavorite}
          isFavorite={favoritedPrompts?.some(fp => fp.id === prompt.id) || false}
        />
      ))}
    </div>
  );
}

export default UserProfileCard;