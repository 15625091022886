import React, { useState, useEffect } from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { ArrowUpIcon, ArrowDownIcon, CopyIcon, PencilIcon, TrashIcon, PlusIcon, MinusIcon } from 'lucide-react';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";

const MAX_CHARS_DESKTOP = 500;
const MAX_CHARS_MOBILE = 265;

function EditablePromptCard({ prompt, onEdit, onDelete, onVote, userVotes, currentUser, isOwnPrompt }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(prompt.text);
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 640);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleCopy = () => {
    navigator.clipboard.writeText(prompt.text);
    setShowCopiedTooltip(true);
    setTimeout(() => setShowCopiedTooltip(false), 2000);
  };

  const handleEdit = () => {
    if (isEditing) {
      onEdit(prompt.id, editedText);
    }
    setIsEditing(!isEditing);
  };

  const handleDeleteClick = () => {
    setShowDeleteDialog(true);
  };


  const handleDeleteConfirm = () => {
    onDelete(prompt.id);
    setShowDeleteDialog(false);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderPromptText = () => {
    if (isEditing) {
      return (
        <textarea
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          className="w-full p-2 border rounded font-mono bg-gray-100 whitespace-pre-wrap"
          rows={10}
        />
      );
    }

    const maxChars = isMobile ? MAX_CHARS_MOBILE : MAX_CHARS_DESKTOP;
    const formattedText = prompt.text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

    return (
      <div className="bg-gray-100 p-4 rounded-md mb-4 overflow-x-auto relative">
        <pre className="text-sm font-mono whitespace-pre-wrap">
          {prompt.text.length <= maxChars || isExpanded
            ? formattedText
            : (
              <>
                {prompt.text.slice(0, maxChars).split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
                ...
              </>
            )
          }
        </pre>
        <div className="mt-2 flex justify-between items-center">
          {prompt.text.length > maxChars && (
            <button
              onClick={toggleExpand}
              className="text-indigo-600 hover:text-indigo-800 font-medium flex items-center"
            >
              {isExpanded ? (
                <>
                  <MinusIcon className="h-4 w-4 mr-1" /> See less
                </>
              ) : (
                <>
                  <PlusIcon className="h-4 w-4 mr-1" /> See more
                </>
              )}
            </button>
          )}
        </div>
      </div>
    );
  };

  const voteCount = prompt.votes || 0;

  const renderVoteButtons = () => {
    if (isOwnPrompt) {
      return (
        <div className="flex space-x-1">
          <Button
            size="sm"
            variant="outline"
            disabled
            className="text-gray-400 cursor-not-allowed">
            <ArrowUpIcon className="h-4 w-4" />
          </Button>
          <Button
            size="sm"
            variant="outline"
            disabled
            className="text-gray-400 cursor-not-allowed">
            <ArrowDownIcon className="h-4 w-4" />
          </Button>
        </div>
      );
    }

    return (
      <div className="flex space-x-1">
        <Button
          size="sm"
          variant="outline"
          onClick={() => onVote(prompt.id, 'up')}
          className="text-green-600 hover:text-green-700 hover:bg-green-100 transition-colors">
          <ArrowUpIcon className="h-4 w-4" />
        </Button>
        <Button
          size="sm"
          variant="outline"
          onClick={() => onVote(prompt.id, 'down')}
          className="text-red-600 hover:text-red-700 hover:bg-red-100 transition-colors">
          <ArrowDownIcon className="h-4 w-4" />
        </Button>
      </div>
    );
  };

  return (
    <div className="group relative">
      <div className="absolute -inset-0.5 bg-gradient-to-r from-pink-300/30 via-purple-300/30 to-blue-300/30 rounded-lg blur-md opacity-0 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
      <Card className="w-full mb-4 transition-all duration-300 ease-in-out transform group-hover:scale-[1.02] bg-white relative">
        <CardContent className="p-6">
          <div className="flex justify-end mb-2">
            <Tooltip.Provider>
              <Tooltip.Root open={showCopiedTooltip}>
                <Tooltip.Trigger asChild>
                  <button
                    onClick={handleCopy}
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    <CopyIcon className="h-4 w-4" />
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Content className="bg-black text-white p-2 rounded">
                  Copied!
                </Tooltip.Content>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
          {renderPromptText()}
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-2 sm:mb-0">
              <p className="font-semibold text-2xl">{voteCount}</p>
              {renderVoteButtons()}
              {isOwnPrompt && (
                <div className="flex space-x-2 mt-2 sm:mt-0">
                  <Button size="sm" variant="outline" onClick={handleEdit}>
                    {isEditing ? 'Save' : <PencilIcon className="h-4 w-4" />}
                  </Button>
                  <Button size="sm" variant="outline" onClick={handleDeleteClick}>
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </div>
            <div className="flex flex-wrap gap-2 mt-2 sm:mt-0">
              <Badge variant="secondary" className="bg-indigo-100 text-indigo-800">{prompt.category}</Badge>
              <Badge variant="outline" className="bg-gray-100 text-gray-800">{prompt.gptModel}</Badge>
              <Badge variant="outline" className="bg-blue-100 text-blue-800">{prompt.language}</Badge>
            </div>
          </div>
        </CardContent>
      </Card>

      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to delete this prompt?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your prompt.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDeleteConfirm} className="bg-red-600 hover:bg-red-700 text-white">
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export default EditablePromptCard;